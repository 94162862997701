.react-datepicker {
    border: 1px solid #EEE !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
    border-radius: 0;
}

.react-datepicker__header {
    background: white;
    border: none;
}

.react-datepicker__triangle {
    border-bottom-color: white !important;
}

.react-datepicker-popper .react-datepicker__triangle::before {
    border-bottom-color: #EEE !important;
}

.react-datepicker__day {
    border-radius: 20px !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    margin: 0.7rem !important;
}

/* .react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
    background-color: #e8edf2;
    color: #000;
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
    padding-left: 0.166rem;
    padding-right: 0.166rem;
}

.react-datepicker__day--today,
.react-datepicker__day--range-start,
.react-datepicker__day--range-end,
.react-datepicker__day--today:hover,
.react-datepicker__day--range-start:hover,
.react-datepicker__day--range-end:hover {
    border-radius: 20px;
    background: #006FAE;
    color: #000;
}
*/
.react-datepicker__day-name {
    color: #919191;
}

.react-datepicker__header-left-button, .react-datepicker__header-right-button {
    color: #006FAE;
}