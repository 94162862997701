@font-face {
  font-family: 'Product Sans';
  src: url('../static/fonts/Product-Sans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Product Sans';
  src: url('../static/fonts/Product-Sans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
